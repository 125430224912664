import _defineProperty from "/builds/u5yGSXwH/8/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var getCartItemPricing = function getCartItemPricing(cartItem) {
  return _objectSpread(_objectSpread({}, cartItem.pricing), {}, {
    currency: cartItem.currency,
    listPriceCurrency: cartItem.listPriceCurrency,
    promotionalMessage: cartItem.promotionalMessage,
    supplementaryMessage: cartItem.supplementaryMessage,
    availabilityOverwriteMessage: cartItem.availabilityOverwriteMessage
  });
};